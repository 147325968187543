import '../sass/frontend.scss';

/* Project specific Javascript goes here. */
console.log('Hello World from frontend.js!');

// Fetch projects from API and put them in a variable called projects

/**
 * Alpine Dashboard Component
 * init - entry point for the alpine component
 *
 * */
